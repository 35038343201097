export {};

const WAFFLE_API = '/api/waffle_status';

window.QUERIES_CACHE_KEY = {
  waffle: {},
  wsGwt: '',
};

const getWaffle = async () => {
  try {
    const response = await fetch(WAFFLE_API);

    if (response.status === 200) {
      window.QUERIES_CACHE_KEY.waffle = await response.json();
    }
  } catch (e) {
    console.log('[INITIAL REQUEST] loading waffle data error: ', e);
  }
};

Promise.all([getWaffle()]).then(() => {
  const element = document.querySelector('#QUERIES_CACHE_STATUS') as HTMLElement;
  element.dataset.status = '1';
});
